import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  singlebox,
  singleboxImg,
  singleboxTitle,
  singleboxStory,
  singleboxStoryActive,
  singleboxEmpty
} from './singlebox.module.scss'


const SingleBox = ({ data, type, className, alt }) => {
  if (type === "title") {
    return (
      <Link to="/about" className={`${singlebox} ${singleboxTitle}`}>
        <h1>It disappears <br />slowly</h1>
      </Link>
    )
  }

  if (type === "title-about") {
    return (
      <Link to="/" className={`${singlebox} ${singleboxTitle}`}>
        <h1>It disappears <br />slowly</h1>
      </Link>
    )

  }

  if (type === "photo") {
    const image = getImage(data)
    return (
      <Link to="/gallery">
        <div className={singlebox}>
          <GatsbyImage className={singleboxImg} image={image} alt={alt} />
        </div>
      </Link>
    )
  }

  if (type === "story") {
    return (
      <Link to={`/${data.slug}`} className={`${className} ${singlebox} ${singleboxStory}`} activeClassName={singleboxStoryActive}>
        {data.frontmatter.title}
      </Link>
    )
  }

  if (type === "empty") {
    return (
      <Link to="/descriptions" className={`${className} ${singlebox} ${singleboxEmpty}`}>
        0
      </Link>
    )
  }
}


export default SingleBox
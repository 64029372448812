import * as React from "react"
import Header from "../components/header"
import SingleBox from "../components/singlebox"
import Grid from "../components/grid"
import { about, about__text } from "./about.module.scss"
import Seo from "../components/seo"

const About = () => {
  return (
    <div className={about}>
      <Seo title="About"/>
      <Header fixed={true}></Header>
      <Grid>
        <SingleBox type="title-about"></SingleBox>
        <div className={about__text}>
          A cura di Chiara Lombardi e Francesca Morrone<br/>
          Fotografie di Chiara Lombardi<br/>
          Reportage narrativi di Naomi Giudice<br/>
          Postfazione di Mistura Allison<br/>
          <br/>
          Un ringraziamento a: Alessandro Atzori, Amantha Aluth Muhandiramlage, Aya, Balla Diagne, Claudia Scano, Claudia Schioppa, Cristina Troisi, Daphne Grieco, Donatella Morrone, Erica Pianalto, Erika Pellicci, Gabriella Lombardi, Hanane Makhloufi, Ifeoma Nneka Emelurumonye, Isabella Conte, Leyla Degan, Lorena Spurio, Marco, Mattia Barro, Michael Marata, Pan Dan, Simona Fredella, Vixinia, ZUZU, Yuri D’Ostuni. A., Aurora Consolo e G.<br/>
          <br/>
          Un grazie speciale a Francesco Caruso e Giorgia Ori.<br/>
          <br/>
          Questo sito è stato pensato come esperienza di supporto al photobook “It disappears slowly” di Chiara Lombardi 
          (selfself, 2021)
        </div>
      </Grid>
    </div>
  )
}
export default About

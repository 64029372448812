import * as React from "react"
import { grid } from "./grid.module.scss"

const Grid = ({children}) => {
  return <div className={grid}>
    {children}
  </div>
}

export default Grid

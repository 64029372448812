import * as React from "react"
import { Link } from "gatsby"

import { header, header__menu, header__menuLeft, headerFixed } from "./header.module.scss"

const Header = ({children, fixed}) => {
  return (
    <div className={`${header} ${fixed ? headerFixed : ''}`}>
      <div className={header__menu}>
        <div className={header__menuLeft}>
          <div>
            <Link to="/">Homepage</Link>
          </div>
        </div>
        <div>
          { fixed ? '' : <Link to="/about">It disappears slowly</Link>}
        </div>
      </div>
      {children}
    </div>
  )
}

export default Header
